@import '../../../../styles/index.scss';

.fullScreenOnMobile {
    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        margin: 0 !important;
        padding: 0 !important;
    }
};

.wrapper {
    width: 50%;
    height: 100%;
    min-height: 450px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 20px;

    @media screen and (max-width: 500px) {
        width: 100%;
        padding: 0;
        margin: 20px 0;
        min-height: 300px;
    }
}

.shade {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.label {
    height: 100%;
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    margin: 0 auto;

    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;

    }
}

.button {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    max-width: 300px;
    padding: 20px 10px;
    background-color: var(--bg);
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: .2s;

    &:hover {
        filter: brightness(97%);
    }
}