.dropdownToggle {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff !important;

    &NoBorder {
        border: none !important;
    }

    &:hover {
        background-color: rgba(0, 0, 0, .05) !important;
    }
}

.flagItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &Wrapper {
        background-color: rgba(0, 0, 0, .05) !important;
    }

    &Flag {
        margin: 0 10px 0 0;
        font-size: 1.25em;
    }

    &Text {
        margin: 0 10px 0 0;
    }

    &Check {
        position: absolute;
        top: 1rem;
        right: 0;
    }
}