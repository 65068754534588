@import '../../../styles/index.scss';

.container {
    @include flex($direction: row, $align: center, $wrap: wrap);
    background-color: $dimmedWhite;
    width: 100%;
}

.itemButton {
    align-self: flex-end;
}
