@import '../../styles/index.scss';

.container {
    position: fixed;
    top: 60px;
    left: 50%;
    z-index: 9999;
    min-width: 50%;
    transform: translateX(-50%);
    background-color: #ffc107;
    border-radius: 10px;

    @media (max-width: 700px) {
        width: 90%;
    }
}

.body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: black;
    font-size: 18px;
    margin: 0;
    flex-wrap: wrap;

    @media (max-width: 500px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
            margin-top: 10px;
        }
    }
}