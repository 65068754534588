$sidebar-width: 250px;

.sidebar {
width: $sidebar-width !important;

    .sidebar-nav {
        width: 100%;
    }

    .nav {
        width: 100%;
    }
}

.sidebar-minimized .sidebar {
    width: 50px !important;
}

html:not([dir="rtl"]) .sidebar {
    margin-left: -$sidebar-width;
}

html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: $sidebar-width;
}

@media (min-width: 992px) {
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: $sidebar-width;
    }
}

.stickyCard_EmailTemplatesTablesPreviewTable {
    position: fixed !important;
    top: 120px;
    left: 5px;
    right: 5px;
    z-index: 1000;
    min-height: 250px;
}

@media (min-width: 992px) {
    .stickyCard_EmailTemplatesTablesPreviewTable {
        position: fixed !important;
        top: 120px;
        left: 5px;
        right: 5px;
        z-index: 1000;
        min-height: 250px;
        transition: left 0.25s;
    }

    body.sidebar-lg-show .stickyCard_EmailTemplatesTablesPreviewTable{
        left: 255px;
        transition: left 0.25s;
    }
}