@import '../../../../styles/index.scss';

.controls {
    @include flex(
        $direction: row,
        $align: center,
        $justify: flex-start,
        $wrap: wrap
    );
    width: 100%;
}

@media (min-width: 759px) {
    .switch {
        margin-left: 1rem !important;
    }
}