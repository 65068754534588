@import '../../../../../../styles/index.scss';

.container {
    width: 100%;
    height: 400px;
    @include flex($justify: center, $align: center)
}

.img {
    background-size: cover;
    border: 1px dotted $gray;
    
    &Banner {
        --width: 16px;
        --height: 9px;
        width: calc(var(--width) * 16);
        height: calc(var(--height) * 16);

        @media screen and (min-width: 435px){
            width: calc(var(--width) * 20);
            height: calc(var(--height) * 20);
        }

        @media screen and (min-width: 500px){
            width: calc(var(--width) * 26);
            height: calc(var(--height) * 26);
        }
    }

    &Tile {
        --width: 32px;
        --height: 35px;
        width: calc(var(--width) * 8);
        height: calc(var(--height) * 8);

        @media screen and (min-width: 500px){
            width: calc(var(--width) * 12);
            height: calc(var(--height) * 12);
        }
    }
}