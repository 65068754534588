@import '../../../../../styles/index.scss';

.alert {
    top: 50px !important;
    text-align: center;
}

.editionField { 
    max-width: 400px;
}

.categorySelector {
    max-width: 600px;
}