.categorySelector {
    padding: 15px;
    min-width: 300px;
    width: 100%;
    outline: none;
    background: none;
    border: 1px solid rgba(0, 0, 0, 1);
    opacity: .6;
    text-align: start;

    i {
        float: right;
        margin-left: 20px;
        font-size: 20px;
    }
}