.label {
    font-weight: 500;
    margin-bottom: 10px;
}

.section {
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    padding: 10px;
    margin-bottom: 20px;
}

.input {
    width: 300px;
}