@import '../../styles/index.scss';

.container {
    display: flex;
    align-items: center;
}

.wrapper {
    width: 64px;
    height: 64px;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid $lightGray;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
    }
}