@import '../../styles/index.scss';

.container {
    @include flex(
        $direction: row,
        $justify: flex-start,
        $align: center
    );
    width: 100%;
    //max-width: 360px;
}

.tooltip {
    cursor: pointer;
}

.icon {
    opacity: .5;
    margin-left: 20px;
    transition: .3s opacity;
    cursor: pointer;

    &:hover {
        opacity: .7;
    }
}
