@import '../../../styles/index.scss';

.column {
    &Status, &Number, &GrossValue {
        min-width: 80px;
    }

    &Name, &Email {
        min-width: 170px;
    }

    &Date, &Attributes {
        min-width: 70px;
    }

    &Actions, &Attachment {
        width: 90px;
    }

    &Currency, &OrderIndex, &Checkbox {
        min-width: 40px;
    }
}

.cell {
    vertical-align: middle !important;
    cursor: pointer;
}

.statusBadge {
    color: $dimmedWhite !important;
}

.highlightedCell {
    color: $lightBlue;
}

.sortableCell {
    display: flex;
    align-items: flex-end;
}