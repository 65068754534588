@import '../../styles/index.scss';

.container {
    @include flex(
        $direction: row,
        $justify: space-between,
        $align: center
    );
    height: 62px;
    padding: 0 .5rem;
    transition: background .3s;

    @media screen and (min-width: map-get($bootstrapBreakpoints, sm)) {
        padding: 0 1rem;
    }

    &:hover {
        background-color: $dimmedWhite;
    }
}

.mainControls {
    &Expander {
        margin: 0 .5rem 0 0;
    }
}

.actionControls {
    @include flex(
        $direction: row,
        $justify: flex-end,
        $align: center
    );

    &Item {
        @include flex(
            $justify: center,
            $align: center
        );
        width: 40px;
        height: 40px;
        opacity: .7;
        transition: opacity .3s;
        border: none;
        background: unset;
        outline: none !important;
        
        &:hover {
            opacity: 1;
            text-decoration: none;
        }
    }
}

.icon {
    &Edit {
        color: $success;
    }
    
    &Delete {
        color: $error;
    }
}