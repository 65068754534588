
.cell {
    vertical-align: middle !important;
    height: 100px;
}

.actionHeader {
    text-align: center;
}

.contentCell {
    max-width: 300px;
    overflow: hidden;
}