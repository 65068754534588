@import '../../../../styles/index.scss';

.footer {
    width: 100%;
    @include flex(
        $direction: row,
        $justify: space-between,
        $align: center,
        $wrap: wrap,
    );

    &AddButton {
        @include flex(
            $direction: row,
            $justify: flex-end,
            $align: center,
        );
    }
}