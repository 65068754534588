@import '../../../styles/index.scss';

.column {
    &Color {
        width: 35px;
    }

    &Name {
        min-width: 200px;
    }

    &Actions {
        width: 50px;
    }
}

.cell {
    vertical-align: middle !important;
}

.color {
    width: 35px;
    height: 35px;
    @include colorTile;
}