@import "../../styles/index.scss";

.expandIcon,
.collapseIcon{
    display: inline-block;
    width: 38px;
    height: 38px;
    margin: 4px;
    cursor: pointer;
}

.expandIcon:hover,
.collapseIcon:hover{
    background-color:#20A8D8;
}

.expandIcon{
    background: #F0F0F0 url('../../assets/expand.png') center center no-repeat;
}
.collapseIcon{
    background: #F0F0F0 url('../../assets/collapse.png') center center no-repeat;
}

.categoryTreeBox{
    overflow: auto;
    text-align: left;
    color:#1B8EB7;
    max-height: 50vh;
    min-height: 300px;
}

.categoryTreeBox i{
    color: black;
}

.categoryTreeBox small{
    margin-left: 1em;
    color: #909090;
}

.controlsView {
    margin-bottom: 30px;
    max-width: 200px;
}

.generalCategoryLabel {
    padding: 10px;
    color:#1B8EB7;
    background-color: rgba(0, 0, 0, 0.075);
}