.switchWithNullState {
	display: inline-block;
    width: 40px;
    height: 26px;
}

.sliderTrue {
	position: relative;
	display: block;
	height: inherit;
	cursor: pointer;
	background-color: #3a9d5d;
	border: 1px solid #c8ced3;
	transition: .15s ease-out;
	border-radius: 50em;
}

.sliderTrue::before {
    position: absolute;
    top: 2px;
    left: 17px;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    content: "";
    background-color: #fff;
    border: 1px solid #c8ced3;
    transition: .15s ease-out;
    border-radius: 50em;
}

.sliderFalse {
	position: relative;
	display: block;
	height: inherit;
	cursor: pointer;
	background-color: #f86c6b;
	border: 1px solid #c8ced3;
	transition: .15s ease-out;
	border-radius: 50em;
}

.sliderFalse::before {
    position: absolute;
    top: 2px;
    left: 2px;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    content: "";
    background-color: #fff;
    border: 1px solid #c8ced3;
    transition: .15s ease-out;
    border-radius: 50em;
}

.sliderNotSet {
	position: relative;
	display: block;
	height: inherit;
	cursor: pointer;
	background-color: #c0c0c0;
	border: 1px solid #c8ced3;
	transition: .15s ease-out;
	border-radius: 50em;
}

.sliderNotSet::before {
    position: absolute;
    top: 2px;
    left: 9px;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    content: "";
    background-color: #fff;
    border: 1px solid #c8ced3;
    transition: .15s ease-out;
    border-radius: 50em;
}