@import '../../styles/index.scss';

.dropdown {
    &Menu {
        max-height: 220px;
        overflow-y: scroll;
        width: 100%;
    }

    &Toggler {
        width: 100%;
        min-width: 200px;
        overflow: hidden;
    }

    &Item {
        min-width: 180px;
        max-width: 100%;
        min-height: 44px;
    }
}

.span {
    white-space: normal;
    right: 0 !important;
}

.color {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    display: inline;
    @include colorTile;
}

.colorInLabel {
    position: absolute;
    left: 10px;
    top: 7px
}

.search {
    width: 100%;
    height: 44px;
    padding: 10px 20px;
    border: 1px #000 solid;
}