@import '../../styles/index.scss';

.container {
    @include flex();
    width: 100%;
}

.dropdown {
    width: 100%;

    &Menu {
        width: 100%;
        max-height: 220px;
        overflow-y: scroll;
    }

    &Toggler {
        width: 100%;   
    }

    &Item {
        width: 100%;
        height: 44px;
    }
}

.search {
    width: 100%;
    height: 44px;
    padding: 10px 20px;
    border: 1px #000 solid;
}