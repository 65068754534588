@import '../../../styles/index.scss';

.cell {
    vertical-align: middle !important;
    min-width: 30px;

    &:last-child {
        text-align: center;
    }
}

.badge, .date, .actions {
    min-width: 70px;
}

.sortableCell {
    display: flex;
    align-items: flex-end;
}

.row {
    cursor: pointer;

    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        cursor: auto;
    }
}

.header:last-child {
    text-align: center;

    span {
        margin-right: 0px !important;
    }
}

.drag {
    cursor: move;
    width: 20px;
    opacity: 0.7;
}