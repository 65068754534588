@import '../../styles/index.scss';

.controls {
    @include flex(
        $direction: column,
        $justify: flex-start,
        $align: center
    );
    width: 100%;

    @media screen and (min-width: map-get($bootstrapBreakpoints, sm)) {
        @include flex(
            $direction: row,
            $align: center,
            $wrap: wrap
        );
    }

    &View {
        @include flex(
            $direction: row,
            $align: center
        );
        margin: 0 0 1rem;

        @media screen and (min-width: map-get($bootstrapBreakpoints, sm)) {
            margin: 0 30px 1rem 0;
        }

        &Label {
            margin: 0 10px 0 0;
        }
    }
}

.fullScreenOnMobile {
    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        margin: 0 !important;
        padding: 0 !important;
    }
};