.flag {
    border: 0.1px solid #000;
    background-size: cover !important;

    @media (min-width: 1101px){
        margin-right: 10px;
    }
}

.name {
    @media (max-width: 1100px){
        display: none;
    }
}

.container {
    padding: 0;
}