@import '../../../styles/index.scss';

.photoCell {
    vertical-align: middle !important;
    height: 100px;
    width: 80px;
}

.photo {
    width: 100%;
}

.row {
    height: 100px;
}

.highlightOnSelect {
    background-color: $highlightSelected;
}

.radioInput { 
    position: static !important;
    margin: 0 !important;
}