@import '../../../styles/index.scss';

.container {
    @include flex(
        $direction: row,
        $justify: space-between,
        $align: center
    );
    position: relative;
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: .25rem;
    margin: .25rem;
}

.color {
    width: 20px;
    height: 20px;
    @include colorTile;
}

.label {
    margin: 0 .25rem;
    width: 60px;
}

.actions {
    @include flex(
        $direction: row,
        $align: center
    );

    &Button {
        @include flex(
            $justify: center,
            $align: center
        );
        margin: 0 .1rem;
        width: 20px;
        height: 20px;
        opacity: .7;
        transition: opacity .3s;
        border: none;
        background: unset;
        outline: none !important;
        
        &:hover {
            opacity: 1;
            text-decoration: none;
        }
    }
}

.icon {
    &Edit {
        margin-top: 2px;
        color: $success;
    }

    &Remove {
        color: $error;
    }
}