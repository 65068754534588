@import '../../../styles/index.scss';

.dropdownToggle {
    background-color: transparent !important;
    border-color: transparent !important;
    padding-bottom: 0 !important;
}

.icon {
    font-size: 1.3rem !important;
}

.numberContainer {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $error;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -7px;
    right: 6px;
}

.number { 
    font-weight: 500;
    color: $dimmedWhite;
}

.container {
    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        position: absolute;
        left: 50px;
    }
}

.dropdownItemContainer { 
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    max-width: 500px;
}

.moduleBadge {
    position: relative !important;
    right: 0 !important;
    width: 100px;
    padding: 5px !important;
    margin-bottom: 10px;
}

.removeIcon {
    color: $error !important;
    font-size: 1.2rem;
}

.date {
    color: $gray;
}

.readedItem {
    background-color: $lightGray !important;
}