.column {
    &Checkbox {
        min-width: 30px;
    }

    &Order {
        width: 50px;
        text-align: center;
    }

    &Photo {
        min-width: 120px;
        width: 120px;
        text-align: center;
    }

    &Active {
        width: 100px;
        text-align: center;
    }

    &Name {
        min-width: 200px;
    }

    &AlternativeText {
        min-width: 200px;
    }

    &IsDefault {
        min-width: 150px;
        text-align: center;
    }

    &Actions {
        width: 90px;
        text-align: center;
    }
}

.cell {
    vertical-align: middle !important;
    height: 100px;
}

.checkbox {
    position: static;
    margin: 0;
    padding: 0;
}

.photo {
    width: 80%;
    max-height: 90px;
}
