.typeSection {
    margin: 20px 0;
    margin-right: 50px;
    border: 1px solid rgba(0, 0, 0, .5);
    max-width: 500px;
    min-width: 300px;
    padding: 20px;
}

.label {
    font-weight: 700;
}