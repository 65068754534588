@import '../../../styles/index.scss';


.nameCell {
    min-width: 160px;
}

.photo {
    width: 50%;
}

.photoCell {
    width: 160px;
}