@import '../../../styles/index.scss';

.input {
    width: 100%;
    max-width: 1000px;
}

.submitError {
    text-align: center;
    margin: 10px 0 0;
    width: 100%;
    color: $error;
    font-size: .9em;
}