@import '../../styles/index.scss';

.buttonContainer {
    @include flex(
        $direction: row
    );

    justify-content: center;
    align-items: flex-end;
    width: 100%;
    margin: 1rem 0;
}