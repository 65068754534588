@import '../../../../../styles/index.scss';

.option {
    padding-left: 10px;
    border-left: solid 1px rgba(0, 0, 0, .5);
    margin: 10px 0;
}

.controls {
    @include flex(
        $direction: column,
        $justify: flex-start,
        $align: flex-start,
    );
    width: 100%;

    @media screen and (min-width: map-get($bootstrapBreakpoints, sm)) {
        @include flex(
            $direction: row,
            $align: center,
            $wrap: wrap
        );
    }
};