@import '../../../styles/index.scss';

.header {
    margin-bottom: 1rem;

    &Label {
        margin: 0 0 0 1rem;
    }
}

.row {
    >div:first-child{
        z-index: 1000;
        min-width: 68px;
    }
}

.nav {
    flex-grow: 0 !important;
    z-index: 10000;
    min-width: 68px !important;
}

.sticky {
    top: 55px !important;
    z-index: 10000;
}

.fullScreenOnMobile {
    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        margin: 0 !important;
        padding: 0 !important;
    }
};

.content{
    z-index: 0;
}