@import '../../styles/index.scss';

.imageContainer {
    max-width: 100%;
    height: 400px;
    margin: 0 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.highlightSelected {
    background-color: rgba(2, 117, 216, .3);
    border-radius: 10px;
}

#highlightBorderSelected {
    border: 10px solid rgba(2, 117, 216, .3);
}