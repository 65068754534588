@import '../../../../../styles/index.scss';

.textInput {
    width: 50%;

    padding: 0 10px;

    @media screen and (max-width: map-get($bootstrapBreakpoints, md)) {
        width: 100%;
    }
}