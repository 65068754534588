@import '../../../styles/index.scss';

.counter {
    font-size: 2.5rem;
    margin: 5px 0;
};

.card {
    width: 250px;
    position: absolute !important; 
    top: 55px;
    right: 10%;

    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        display: none;
    }
}