.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
}

.iconPositionLeft,
.iconPositionRight {
    display: flex;
    align-items: center;
}

.iconPositionLeft {
    flex-direction: row-reverse;

    svg {
        margin-right: 10px;
    }
}

.iconPositionRight {
    flex-direction: row;

    svg {
        margin-left: 10px;
    }
}

.scaleUp:hover {
    letter-spacing: 1px;
    box-shadow: 5px 40px -10px rgba(0,0,0,0.57) !important;
}

.lightUp:hover {
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
}

.switchColors:hover {
    color: #ffffff !important;
    background: rgba(0, 0, 0, .5);
    border-color: #fff !important;
}

.roundUp:hover {
    color: #494949 !important;
    border-radius: 50px !important;
    border-color: #494949 !important;
}