@import '../../../styles/index.scss';

.nameCell {
    min-width: 200px;
}

.imageCell {
    width: 80px;
}

.image {
    width: 100%;
}

.redBackground:hover {
    background-color: $lightError !important;
}