@import '../../../../../styles/index.scss';

.footer {
    margin: 2rem 0 0;
    width: 100%;
    @include flex(
        $direction: row,
        $justify: flex-end
    );
}

.table {
    max-width: 400px;

    th {
        vertical-align: middle !important;
    }
}