@import '../../../styles/index.scss';

.nameCell {
    min-width: 160px;
}

.image {
    width: 100%;
}

.imageCell {
    width: 80px;
}