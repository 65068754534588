// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// ie fixes
@import "ie-fix";

// If you want to add something do it here
@import "../styles/index.scss";

@import "./custom.scss";

.ps {
    .nav-dropdown-items > .nav-item > .nav-link {
        padding-left: 30px;
    }

    .nav-item > .nav-link {
        display: flex;
        align-items: flex-start;
    }

    .nav-item > .nav-link > .nav-icon {
        margin-top: 4px;
    }
}

//added style for proper position: sticky displaying in admin-panel
.app-body {
    overflow: visible;
}
