@import '../../styles/index.scss';

label {
    @include flex(row, flex-start, center);
}

.flag {
    margin: 0 10px 0 5px;
    border: 0.1px solid #000;
}

.toCheck {
    margin: 0 5px;

    &:hover {
        cursor: pointer;
    }
}

.button {
    padding: 0;
    background-color: transparent;
    border: none;
}
