@import "../../../styles/index.scss";

.cell {
    vertical-align: middle !important;
}

.column {
    &Name {
        min-width: 140px;
    }

    &Checkbox {
        text-align: center;
    }
}

.drag {
    cursor: move;
    width: 20px;
    opacity: 0.7;
}

.clearButton {
    font-size: 1em;
    color: gray;
    position: absolute;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}