@import "../../styles/index.scss";

.searchBar {
    background-color: #F0F0F0;
}

.searchBarLabel{
    white-space: nowrap;
    vertical-align: middle;
}

.expandIcon,
.collapseIcon{
    display: inline-block;
    width: 38px;
    height: 38px;
    margin: 4px;
    cursor: pointer;
}

.expandIcon:hover,
.collapseIcon:hover{
    background-color:#20A8D8;
}

.expandIcon{
    background: #F0F0F0 url('../../assets/expand.png') center center no-repeat;
}
.collapseIcon{
    background: #F0F0F0 url('../../assets/collapse.png') center center no-repeat;
}

.categoryTreeBox{
    overflow: auto;
    text-align: left;
    color:#1B8EB7;
}

.categoryTreeBox i{
    color: black;
}

.categoryTreeBox small{
    margin-left: 1em;
    color: #909090;
}