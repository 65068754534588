@import '../../../styles/index.scss';

.formContainer {
    @media screen and (max-width: map-get($bootstrapBreakpoints, md)) {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.nameInput {
    width: 100%;
    max-width: 500px;
}

.sectionTypeDropdown {
    min-width: 250px;
}