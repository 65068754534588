@import "../../../styles/index.scss";

.cell {
    vertical-align: middle !important;
}

.column {
    &isActive {
        min-width: 100px;
    }

    &Actions {
        width: 90px;
    }
}

.rowDisabled {
    cursor: auto;
}