@import '../../../../styles/index.scss';

.fullScreenOnMobile {
    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        margin: 0 !important;
        padding: 0 !important;
    }
};

.button {
    margin-top: 25px;
    padding: 10px 20px;
    background-color: var(--bg);
    border: none;
    cursor: pointer;
    transition: .2s;

    &:hover {
        filter: brightness(97%);
    }
}

.container {
    margin: 50px auto;
    max-width: 100%;
    overflow: hidden;
    max-width: 1920px;
    width: 100%;
    min-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    cursor: pointer;
}