@import '../../../../styles/index.scss';

.controls {
    @include flex(
        $direction: column,
        $align: flex-start,
        $justify: center
    );
    width: 100%;

    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        @include flex(
            $direction: row,
            $justify: center,
            $wrap: wrap
        );
    }
};