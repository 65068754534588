@import './colorPalette.scss';
@import './breakpoints.scss';
@import './mixins.scss';
@import './quillStyles.scss';
@import './reactDatePicker.scss';
@import './tinyMCEStyles.scss';
@import './leafletMap.scss';

.nav-item.hidden,
.nav-title.hidden {
    display: none;
}

modal-dialog.modal-full {
    max-width: 90vw;
}

@media (min-width: 576px)
{
    .modal-dialog.modal-full {
        max-width: 90vw;
    }
}

.card-header {
    color: white;
    background-color: #1578BF;
}
.page-item.active .page-link{
    background-color: #1578BF;
    border-color: #1578BF;
}
.btn-primary {
    color: #fff;
    background-color: #1578BF;
    border-color: #1578BF;
}