.container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: progress;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .5);
    z-index: 9999;

    &Visible {
        display: flex;
    }
}