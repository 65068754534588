@import '../../../styles/index.scss';


.nameCell {
    min-width: 160px;
}

.photo {
    max-width: 100%;
    max-height: 90px;
    padding: 10px;
}

.photoCell {
    width: 160px;
}

.order {
    width: 40px;
}