@import '../../../../../styles/index.scss';

.title {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .tab-content {
        border-bottom: none !important;
    }

    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        height: 300px;
    }
}

.ordersSection {
    height: 550px;
    overflow-y: scroll;
    width: 100%;

    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        height: 100%;
        overflow-y: auto;
        margin: 20px 0;
    }
};

.fullScreenOnMobile {

    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        margin: 0 !important;
        padding: 0 !important;
    }
};