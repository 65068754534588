@import '../../../styles/index.scss';

.container {
    margin: 1rem 0;
}

.borderWrapper {
    border: 1px solid $gray;
}

.list {
    list-style: none;
    padding: .25rem;
    margin: 0;
}

.main {
    width: 100%;

    &Fallback {
        padding: 2rem 1rem;
    }
}

.controls {
    margin: 2rem 0 0;
    width: 100%;
    @include flex(
        $direction: row,
        $justify: flex-end
    );
}