@import "../../../styles/index.scss";

.container {
    @include flex(
        $direction: row,
        $justify: center,
        $align: center
    );

    @media screen and (max-width: map-get($bootstrapBreakpoints, md)) {
        flex-wrap: wrap;
    }
}

.item {
    @include flex(
        $justify: center,
        $align: center
    );
    /*width: 40px;*/
    height: 40px;
    opacity: .7;
    transition: opacity .3s;
    border: none;
    background: unset;
    outline: none !important;

    &:hover {
        opacity: 1;
        text-decoration: none;
    }

    &:disabled {
        opacity: 0.25;
        text-decoration: none;
    }
}


.edit {
    color: $success;
}

.delete {
    color: $error;
}

.showDetails {
    color: $primary;
}

.print {
    color: $lightBlue;
}

.accept {
    color: $success;
}