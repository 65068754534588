@import '../../../styles/index.scss';

.info {
    color: $error;
    margin: 7px 10px;
};

.flash {
    animation: flashing 2.5s linear infinite, ;
}

@keyframes flashing {
    0% {
        opacity: 100%;
    };
    50% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}

.mobileIcon {
    color: $error;
    position: absolute;
    left: 60px;
    font-size: 2rem !important;
}