.dropdown {
    width: auto;
    max-width: 300px;
}

.container {
    min-height: 500px;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    background-size: cover !important;
    background-position: center !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 768px) {
        width: 100%;
        min-height: 50vh;
        max-height: 100%;
    }
};

.mobileWidth {
    width: 330px;
}

.contentContainer {
    width: 100%;
    max-width: 1920px;
    height: 100%;
}

.button {
    margin-top: 25px;
    padding: 10px 20px;
    background-color: var(--bg);
    border: none;
    cursor: pointer;
    transition: .2s;

    &:hover {
        filter: brightness(97%);
    }
}