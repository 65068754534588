@import '../../../../styles/index.scss';

.scrollable {
    height: 50vh;
    overflow: auto;
}

.listItem {
    &:hover {
        background-color: $lightGray;
    }
}