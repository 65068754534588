@import '../../styles/index.scss';

.container {
    margin: 50px;
    width: 100%;
    height: 500px;

    @media screen and (max-width: map-get($bootstrapBreakpoints, md)) {
        margin: 20px 10px;
    }
}

