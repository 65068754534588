@import '../../../styles/index.scss';

.column {
    &Name {
        min-width: 200px;
    }

    &Active {
        > td {
            border-top: 0;
        }
    }

    &Color {
        min-width: 100px;

        .preview {
            align-self: center;
            width: 30px;
            height: 30px;
            margin-right: 20px;
            padding: 1rem;
            @include colorTile;
        }
    }

    &Mapping {
        text-align: left;
    }

    &Actions {
        text-align: right;
    }
}

.cell {
    vertical-align: middle !important;
}