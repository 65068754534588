// react-calender styles
.react-calendar {
    width: 350px;
    max-width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-date-picker__wrapper {
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    background: white;
    height: 35px;
    padding: 0 5px;
}

.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
}
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    background: #006edc;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

// Overrides


.react-date-picker__calendar {
    z-index: 10;
    opacity: 1;
    transform: none;
    transition: opacity 319ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 212ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 28px;
    left: 16px;
    transform-origin: -16px 0px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    border: unset;
}

.react-date-picker__calendar--closed {
    opacity: 0;
    display: none;
}

.react-date-picker__button svg {
    display: none;
}
.react-calendar__navigation {
    height: unset !important;
}
.react-calendar__navigation button[disabled] {
    background-color: #e6e6e6;
}
.react-calendar__month-view__days .react-calendar__tile:enabled:hover {
    background-color: #e6e6e6;
    border-radius: 50%;
}

.react-calendar__navigation button:enabled:focus {
    background-color: unset;
}
.react-calendar__navigation button:enabled:hover {
    background-color: #e6e6e6;
}
.react-calendar__month-view__days {
    padding: 6px;
}

.react-date-picker__inputGroup__input:invalid {
    background: unset;
}

abbr[title] {
    text-decoration: none;
}
.react-calendar {
    border: unset;
}

.react-calendar__month-view__weekdays {
    background-color: lightgray;
    text-transform: lowercase;
    padding: 6px;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.38);
}

.react-calendar__month-view__weekdays__weekday {
    margin: 0 2px;
}

.react-calendar button {
    width: 50px;
    height: 50px;
}
.react-calendar__month-view__days .react-calendar__tile--active {
    border-radius: 50%;
}
.react-calendar__year-view .react-calendar__tile {
    padding: 0;
}

.react-calendar__tile:disabled {
    background-color: unset;
    color: #e6e6e6;
}

.react-calendar__navigation {
    margin-bottom: 0;
    height: unset;
}

.react-date-picker__calendar {
    height: unset !important;
}