@import '../../../../styles/index.scss';

.fullScreenOnMobile {
    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        margin: 0 !important;
        padding: 0 !important;
    }
};

.button {
    
    &FullScreen {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}

.container {
    margin: 50px auto;
    max-width: 100%;
    overflow: hidden;
    max-width: 1920px;
    width: 100%;
    padding: 50px;
    cursor: pointer;
    position: relative;

    &Left, &Right {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &Left {
        flex-direction: row-reverse;
    }
}

.videoContainer {
    width: 50%;

    &Full {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
    }
}

.content {
    width: 50%;
    &FullScreen {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}