@import '../../../styles/index.scss';

.controls {
    @include flex(
        $direction: column,
        $align: flex-start,
        $justify: center
    );
    width: 100%;

    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        @include flex(
            $direction: row,
            $justify: center,
            $wrap: wrap
        );
    }
};

.downloadLink {
    color: $dimmedWhite !important;
}

.fullScreenOnMobile {
    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        margin: 0 !important;
        padding: 0 !important;
    }
};
