.wrapper {
    width: 100px;
    margin: 10px;
    max-height: 150px;
    text-align: center;
    position: relative;

    span {
        height: 50px;
        font-size: 12px;
        text-align: center;
        white-space: wrap;
    }
}

.item {
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: contain;
    border-radius: 5px;
    cursor: pointer;
    background-repeat: no-repeat;
}

.checkbox {
    display: none;
    position: absolute;
    top: -5px;
    left: -5px;

    &Visible {
        display: block;
    }
}