@import '../../styles/index.scss';

.footer {
    width: 100%;
    @include flex(
        $direction: row,
        $justify: flex-end,
        $align: center,
        $wrap: wrap,
    );
}

.modal {
    max-width: 1920px !important;
}