@import '../../../styles/index.scss';

.column {
    &Checkbox {
        width: 30px;
    }

    &Categories {
        min-width: 200px;
    }

    &Actions {
        width: 50px;
    }
}

.cell {
    vertical-align: middle !important;
}

.greenBackground:hover {
    background-color: $lightSuccess !important;
}
