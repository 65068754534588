@import '../../../styles/index.scss';

.buttonContainer {
    @include flex(
        $direction: row
    );
    align-items: flex-end;
    width: 100%;
    margin: 1rem 0;
}

.preview {
    align-self: center;
    width: 30px;
    height: 30px;
    margin-right: 20px;
    padding: 1rem;
    @include colorTile;
}