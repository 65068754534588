@import '../../../../../styles/index.scss';

.fullScreenOnMobile {
    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        margin: 0 !important;
        padding: 0 !important;
    }
};

.dropdown {
    max-width: 300px;
}