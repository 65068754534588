
.cell {
    vertical-align: middle !important;
    height: 100px;
}

.cellBottom {
    vertical-align: bottom !important;
    height: 100px;
}

.imageContainer {
    width: 120px;
}

.image {
    width: 100%;
    max-height: 100%;
}

.actionHeader {
    text-align: center;
}

.content {
    max-width: 200px;
}

.row {
    cursor: pointer;
}