.fieldContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.field {
    max-width: 300px;
}

.flag {
    margin: 0 10px 0 5px;
    border: 0.1px solid #000;
}