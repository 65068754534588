@import '../../styles/index.scss';

.container {
    @include flex(
        $direction: column,
    );
    width: 100%;
}

.preview {
    margin: 1rem 0;
    align-self: center;
    width: 200px;
    height: 200px;
    @include colorTile;
}

.colors {
    @include flex(
        $direction: row,
        $wrap: wrap
    )
}

.placeholder {
    align-self: center;
    margin: 1rem 0;
}