@import '../../../styles/index.scss';

.controls {
    margin: 2rem 0 0;
    width: 100%;
    @include flex(
        $direction: row,
        $justify: flex-end
    );
}

.alert {
    top: 50px !important;
    text-align: center;
}