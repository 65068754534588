@import '../../styles/index.scss';

.container {
    position: absolute;
    z-index: 2;
}

.cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.preview {
    width: 35px;
    height: 35px;
    border: solid 2px $dimmedWhite;
    border-radius: 50%;
}

.controls {
    @include flex(
        $direction: row,
        $justify: space-between
    );
    margin: .5rem 0 0;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px;
    padding: .25rem;
    background-color: #fff;
    position: relative;
}