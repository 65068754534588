.mapHeaderInput {
    width: 100%;
    max-width: 500px;
}

.mapSizeInput {
    width: 100%;
    max-width: 300px;
    z-index: 9999;
}

.textFieldContainer {
    max-width: 300px;
    width: 100%;
}