
/* kolory admin panelu */

$error: #f86c6b;
$success: #4dbd74;
$dimmedWhite: #eeeeee;
$lightGray: #dddedf;
$gray: #d3d3d3;
$primary: #007bff;
$lightBlue: #20a8d8;
$lightError: #e7c8c8;
$lightSuccess: #c8dbcf;
$highlightSelected: #baffb3;
$hoverBackgroundRow: rgba(0, 0, 0, 0.075);