@import "../../../styles/index.scss";

.categoryTreeItemBox {
    padding: 10px 0px;
    cursor: pointer;
    background-color: transparent;
}

.categoryTreeItemBoxSelected {
    padding: 10px 0px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.075);
}

.expandLink,
.expandFiller {    
    display: inline-block;
    width: 20px;
}