@import '../../../styles/index.scss';


.nameCell {
    min-width: 160px;
}

.photo {
    width: 50%;
    padding: 10px;
}

.photoCell {
    width: 160px;
}

.order {
    width: 40px;
}