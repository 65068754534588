.iconContainer { 
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.selected {
    border: 4px solid #0275d8;
}