@import '../../../../../styles/index.scss';

.tab {
    &Header {
        @include flex(
            $justify: center,
            $align: center
        );

        border: none;
        background-color: unset;
        opacity: .3;
        height: 21px;
    }

    &List {
        @include flex($direction: column);
        list-style: none;
        padding: 0;
        margin: 0;

        &Item {
            height: 3rem;
            width: 100%;
        }
    }

    &Button {
        position: relative;
        @include flex(
            $direction: row,
            $justify: center,
            $align: center,
        );
        width: 100%;
        height: 100%;
        background-color: unset;
        border: none;
        outline: none !important;

        &:hover {
            background-color: $lightBlue;
            color: $dimmedWhite;
            .tabButtonLabel {
                display: flex;
            }
        }

        &:focus {
            background-color: $lightBlue;
            color: $dimmedWhite;
        }

        &Label {
            @include flex(
                $justify: center,
                $align: center,
            );
            position: absolute;
            display: none;
            left: 54px;
            top: 0;
            bottom: 0;
            background-color: $lightBlue;
            color: $dimmedWhite;
            width: 200px;
            z-index: 1;
        }

        &Selected {
            background-color: $lightBlue;
            color: $dimmedWhite;
        }
    }
}
