@import '../../../styles/index.scss';

.footer {
    margin: 2rem 0 0;
    width: 100%;
    @include flex(
        $direction: row,
        $justify: flex-end
    );
}

.fullScreenOnMobile {
    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        margin: 0 !important;
        padding: 0 !important;
    }
};

.table {
    max-width: 400px;

    th {
        vertical-align: middle !important;
    }
}