.filePreview {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 1024px;
    width: 728px;
    margin: 0 auto;
    max-height: 80vh;
    max-width: 80vw;

    @media screen and (max-width: 740px) {
        max-height: 50vh;
    }
}