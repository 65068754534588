@import "../../../styles/index.scss";

.cell {
    vertical-align: middle !important;
}

.column {
    &Checkbox {
        width: 30px;
    }

    &Email {
        min-width: 180px;
    }

    &Actions {
        width: 50px;
    }
}

.redBackground:hover {
    background-color: $lightError !important;
}