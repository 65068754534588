@import '../../styles/index.scss';

.container {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: map-get($bootstrapBreakpoints, md)) {
        width: 100%;
    }

}