@import '../../../../styles/index.scss';

.fullScreenOnMobile {
    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        margin: 0 !important;
        padding: 0 !important;
    }
};

.container {
    display: flex;
    align-items: center;
    max-height: 700px;
    margin: 50px auto;
    max-width: 100%;
    justify-content: center;
    overflow: hidden;
    width: 1200px;
}

.image {
    width: 45%;
    height: 90%;
}

.label {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    margin: 0 auto;

    div.buttonContainer {
        margin-top: 30px;
        min-width: 400px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        padding: 20px;

        div.buttonContainer {
            margin-top: 30px;
            width: 100%;
        }
    }
}

.button {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    padding: 20px 10px;
    background-color: var(--bg);
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: .2s;

    &:hover {
        filter: brightness(97%);
    }
}

.wrapper {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 20px;

    &Reverse {
        flex-direction: row-reverse;
    }


    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
}