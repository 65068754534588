.orderDetailsContainer{
    margin: 0px;
    background-color: lightyellow;
    padding: 5px;

    label{
        font-weight: bold;
    }

    fieldset{
        margin: 0;
        padding: 0;
        
        table{
            tr{
                &:hover{
                    background-color: transparent;
                }
                th{
                    font-weight: normal;
                    border: 0px;
                }
                td {
                    font-weight: bold;
                    color: #666;
                    border: 0px;
                }
            }
        }
    }
}

.orderDetailsContainerRow{
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
}