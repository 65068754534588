.item {
    width: 100%;
    height: 90px;
    margin: 0 auto;

    span{
        font-size: 48px;
        position: relative;
        top: 5px;
    }
}

.container {
    width: 90px;
    height: auto;
    margin: 10px;
    text-align: center;
    cursor: pointer;
}