@import '../../styles/index.scss';

.footer {
    margin: 2rem 0 0;
    width: 100%;
    @include flex(
        $direction: row,
        $justify: flex-end
    );
}

.header {
    cursor: pointer;
}