.section {
    padding: 20px 10px;
    width: 50%;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, .2);
}

.previewContainer {
    min-height: 200px;
    position: sticky;
    top: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    z-index: 100;
}