@import '../../styles/index.scss';

$row-padding: 10px;

.rowWrapper {
    padding: $row-padding $row-padding $row-padding 0;
    height: 100%;
    box-sizing: border-box;

    &:active {
        opacity: 1;
    }
}

.rowWrapperDragDisabled {
    cursor: default;
    &:hover {
        opacity: 1;
    }
}

.row {
    height: 100%;
    white-space: nowrap;
    display: flex;

    & > * {
        box-sizing: border-box;
    }
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rowLandingPad {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;

    * {
        opacity: 0 !important;
    }

    &::before {
        background-color: lightblue;
        border: 3px dashed white;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rowCancelPad {
    @extend .rowLandingPad;

    &::before {
        background-color: #e6a8ad;
    }
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rowSearchMatch {
    outline: solid 3px #0080ff;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rowSearchFocus {
    outline: solid 3px #fc6421;
}

%rowItem {
    display: inline-block;
    vertical-align: middle;
}

.rowContents {
    @extend %rowItem;
    position: relative;
    height: 100%;
    border: solid #bbb 1px;
    box-shadow: 0 2px 2px -2px;
    border-radius: 2px;
    min-width: 300px;
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.rowLabel {
    @extend %rowItem;
    flex: 0 1 auto;
    width: 100%;
}

.rowToolbar {
    @extend %rowItem;
    flex: 0 1 auto;
    display: flex;
}

.moveHandle {
    @extend %rowItem;
    @include flex(
        $align: center,
        $justify: center
    );
    height: 100%;
    width: 44px;
    background: #20a8d8;
    border: solid #aaa 1px;
    box-shadow: 0 2px 2px -2px;
    cursor: move;
    border-radius: 1px;
    z-index: 1;

    &Icon {
        color: #fff;
    }
}

.loadingHandle {
    @extend .moveHandle;

    cursor: default;
    background: #d9d9d9;
}

@keyframes pointFade {
    0%,
    19.999%,
    100% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
}

.loadingCircle {
    width: 80%;
    height: 80%;
    margin: 10%;
    position: relative;
}

.loadingCirclePoint {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    $point-count: 12;
    $spin-animation-time: 800ms;

    &:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 11%;
        height: 30%;
        background-color: #fff;
        border-radius: 30%;
        animation: pointFade $spin-animation-time infinite ease-in-out both;
    }

    @for $i from 1 through (($point-count + 1) / 2) {
        &:nth-of-type(#{$i}) {
            transform: rotate(360deg / $point-count * ($i - 1));
        }

        &:nth-of-type(#{$i + $point-count / 2}) {
            transform: rotate(180deg + 360deg / $point-count * ($i - 1));
        }

        &:nth-of-type(#{$i}),
        &:nth-of-type(#{$i + $point-count / 2}) {
            &:before {
                animation-delay: -$spin-animation-time +
                    ($spin-animation-time / $point-count * 2 * ($i - 1));
            }
        }
    }
}

.toolbarButton {
    @extend %rowItem;
}

.rowTitle {
    font-weight: bold;
}

.rowTitleWithSubtitle {
    font-size: 85%;
    display: block;
    height: 0.8rem;
}

.rowSubtitle {
    font-size: 70%;
    line-height: 1;
}

.expander {
    @include flex(
        $align: center,
        $justify: center
    );
    border: none;
    position: absolute;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #000;
    color: $primary;
    width: 16px;
    height: 16px;
    padding: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s;
    cursor: pointer;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;

        .icon {
            color: #83bef9;
        }
    }

    &:hover:not(:active) {
        width: 20px;
        height: 20px;
    }
}

.icon {
    display: inline-block;
    color: #000;
}

/**
 * Line for under a node with children
 */
.lineChildren {
    height: 100%;
    display: inline-block;
    position: absolute;

    &::after {
        content: '';
        position: absolute;
        background-color: black;
        width: 1px;
        left: 50%;
        bottom: 0;
        height: $row-padding;
    }
}
