.imageContainer {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.image {
    max-width: 100%;
    max-height: 100%;
}