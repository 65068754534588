@import '../../../styles/index.scss';

.container {
    margin: 0rem;
    height: 58.5vh;
}

.node {
    @include flex(
        $direction: row,
        $justify: space-between,
        $align: center
    );
    position: relative;
    padding: 0 0 0 5px;

    &Button {
        @include flex(
            $justify: center,
            $align: center
        );
        border-radius: 50%;
        border: none;
        background-color: $gray;
        position: absolute;
        height: 20px;
        width: 20px;
        right: -40px;
        transition: all .3s;
        transform-origin: center center;

        &:focus {
            outline: none;
            box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
        }

        &:hover {
            background-color: $primary;
            right: -45px;
            height: 30px;
            width: 30px;

            .nodeButtonIcon {
                color: #fff;
            }
        }

        &Icon {
            transition: color .3s;
        }
    }
}

.inactive {
    background-color: rgba(179, 187, 194, 0.5);;
}

.alert {
    top: 50px !important;
    text-align: center;
}