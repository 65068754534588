@import "../../../styles/index.scss";

.cell {
    vertical-align: middle !important;
}

.column {
    &Name {
        min-width: 130px;
    }

    &ShortName {
        min-width: 100px;
    }

    &IsActive {
        min-width: 100px;
    }

    &Regions {
        min-width: 100px;
    }

    &Actions {
        width: 90px;
    }
}

.regionListItem{
    &:after{
        content:", ";
    }
    &:last-child:after{
        content:"";
    }
}

.cursor {
    cursor: pointer !important;
}