.column {
    &Checkbox {
        width: 10px;
    }

    &FeatureName {
        min-width: 100px;
    }

    &FeatureType {
        min-width: 100px;
    }

    &FeaturePresentation {
        min-width: 100px;
    }

    &Actions {
        width: 90px;
    }
}

.cell {
    vertical-align: middle !important;
}

.checkbox {
    position: static;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

