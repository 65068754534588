.section {
    padding: 20px;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
}


.sectionWithRange {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    padding-bottom: 20px;

    div.range {
        min-width: 30%;
        margin: 20px 40px;
    }
}