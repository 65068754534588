@import '../../../styles/index.scss';


.nameCell {
    min-width: 160px;
}

.photo {
    width: 100%;
}

.photoCell {
    width: 80px;
}