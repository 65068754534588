@import '../../../styles/index.scss';

.container {
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: map-get($bootstrapBreakpoints, sm)) {
        margin: 20px 0;
        width: 100%;
    }
}

.imageContainer {
    max-width: 100%;
    height: 100%;
    max-height: 150px;
}

.image {
    height: 150px;
}

.highlightSelected {
    background-color: rgba(2, 117, 216, .3);
    border-radius: 10px;
}