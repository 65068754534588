@import '../../styles/index.scss';

.container {
    @include flex(
        $direction: row,
        $wrap: wrap
    );
}

.switch {
    @include flex(
        $direction: row,
    );
}