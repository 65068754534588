@import '../../styles/index.scss';

.footerLeftButtons {
    width: 100%;
    @include flex(
        $direction: row,
        $justify: flex-start,
        $align: center,
        $wrap: wrap,
    );
}

.footerRightButtons {
    width: 100%;
    @include flex(
        $direction: row,
        $justify: flex-end,
        $align: center,
        $wrap: wrap,
    );
}