.divider {
    width: 90%;
    height: 1px;
    background-color: #e4e7ea;
    margin: 15px auto;
}

.languageDropdown {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;

    @media screen and (min-width: 768px) {
        top: 2.5rem;
        right: 2.5rem;
    }
}