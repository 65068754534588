.sectionContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.value {
    font-weight: 700;
    padding-left: .2rem
}

.line {
    display: block;
    padding: .3rem 0;
}

.section {
    width: 400px;
    padding: 1rem 0;
}