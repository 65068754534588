/* Styles need for quill editor */
.quillAlign-center {
    text-align: center !important;
}

.quillAlign-right {
    text-align: right !important;
}

.ql-container div.ql-editor {
    height: 200px !important;
}