@import '../../../styles/index.scss';

.customPhotoCell {
    vertical-align: middle !important;
    height: 50px !important;
    width: 50px !important;
}

.photo {
    width: 100%;
}

.row {
    height: 100px;
}

.highlightOnSelect {
    background-color: $highlightSelected;
}

.highlightOnCheck {
    background-color: $hoverBackgroundRow;
}

.table {
    max-height: 400px;
    overflow: auto;
}