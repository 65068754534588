@import '../../../../../styles/index.scss';

.tab {
    &Header {
        @include flex(
            $direction: row,
            $justify: space-between,
            $align: center
        );
        width: 100%;

        &Button {
            border: none;
            background-color: unset;
            opacity: .3;
            transition: all .3s;

            &:hover {
                background-color: $lightGray;
                opacity: 1;
            }
        }
    }

    &List {
        @include flex($direction: column);
        width: 100%;
        list-style: none;
        padding: 0;
        margin: 0;
        overflow: hidden;

        &Item {
            height: 3rem;
            width: 100%;
        }
    }

    &Button {
        @include flex(
            $direction: row,
            $justify: flex-start,
            $align: center,
        );
        width: 100%;
        height: 100%;
        background-color: unset;
        border: none;
        transition: background .5s;
        outline: none !important;

        &:hover, &:focus {
            background-color: $dimmedWhite;

            .tabButtonLabel {
                opacity: 1;
            }
        }

        &Label {
            opacity: .7;
            transition: opacity .3s;

            &Selected {
                opacity: 1;
            }
        }
    }
}