@mixin flex($direction: column, $justify: flex-start, $align: flex-start, $wrap: nowrap) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
    flex-wrap: $wrap;
}

@mixin colorTile {
    position: relative;
    outline: solid 2px $dimmedWhite;
    display: block;

    &::before {
        @include flex(
            $direction: column,
            $justify: center,
            $align: center
        );
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        color: $error;
        content: '\274c';
        opacity: 0;
    }

    &Empty {
        &::before {
            opacity: 1;
        }
    }
}