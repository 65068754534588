@import "../../../styles/index.scss";

.ascIcon {
    color: $success;
};

.descIcon {
    color: $error;
}

.container {
    cursor: pointer;
    margin-left: 10px;
}