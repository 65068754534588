@import "../../styles/index.scss";

.edit {
    color: $success !important;
    padding-top: 3px;
}
    
.delete {
    color: $error !important;
}

.showDetails {
    color: $primary !important;
}

.print {
    color: $lightBlue !important;
}

.label {
    margin-right: 20px;
}

.item {
    display: flex;
    justify-content: space-between;
}